import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import './login.scss'
import AuthService from "src/service/authService";
import {useAuth} from "src/hook/AuthProvider";

const Login = (props: any) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [usernameError, setUsernameError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const {login} = useAuth()
    const navigate = useNavigate()

    const onButtonClick = () => {
        AuthService.login(username, password)
            .then(resp => {
                    login(resp)
                    navigate('/home')
                }
            )
            .catch((error) => console.log(error))//todo: popup
    }

    return (
        <div className={'mainContainer'}>
            <div className="auth-image"/>
            <div className="layout-container">
                <div className="auth-logo"/>
                <div className="auth-box">
                    <div className="white-line"/>
                    <div className={'titleContainer'}>
                        <div>Samothrace</div>
                    </div>
                    <div className={'inputContainer'}>
                        <input
                            value={username}
                            placeholder="Email"
                            onChange={(ev) => setUsername(ev.target.value)}
                            className={'inputBox'}
                        />
                        <label className="errorLabel">{usernameError}</label>
                    </div>
                    <br/>
                    <div className={'inputContainer'}>
                        <input
                            value={password}
                            type={'password'}
                            placeholder="Password"
                            onChange={(ev) => setPassword(ev.target.value)}
                            className={'inputBox'}
                        />
                        <label className="errorLabel">{passwordError}</label>
                    </div>
                    <br/>
                    <div className={'buttonContainer'}>
                        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Accedi'}/>
                    </div>
                </div>
            </div>
            <div className={'footer-unict-logo'}></div>
            <div className={'footer-alfameg-logo'}></div>

        </div>
    )
}

export default Login
