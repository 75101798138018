import React from "react";
import {useNavigate} from "react-router-dom";

const Header = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        console.log("Logout");
        localStorage.removeItem("token");
        navigate("/login");
    }

    return (
        <header className="header">
            <div className="header-title"></div>
            <button className="logout-button" onClick={handleLogout}>Esci</button>
        </header>
    );
};

export default Header;