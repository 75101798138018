import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Footer from "src/shared/footer/Footer";
import Header from "src/shared/header/Header";
import {HomeComponent} from "src/page/home/HomeComponent";
import {jwtDecode} from "jwt-decode";
import {CustomJwtPayload} from "src/service/conf/JwtTokenConfig";

const Home = () => {

    const [username, setUsername] = useState('')


    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            const decodedToken = jwtDecode<CustomJwtPayload>(token);
            console.log(decodedToken);
            setUsername(decodedToken.user);
        }

    })

    return (
        <div className="mainContainerHome">
            <div className="auth-image"/>
            <Header/>
            <div className="contentContainer">
                <div className={'titleContainer'}>
                    <div>Benvenuto, {username}!</div>
                </div>
                <HomeComponent/>
            </div>
            {/* Inserisci il Footer qui */}
            <Footer/>
        </div>
    );
};

export default Home;
